<template>
  <div>
    <v-snackbar
        v-model="states.alertSnack.status"
        :timeout="10000"
        :color="states.alertSnack.color"
        fixed
        right
        top
        elevation="24"
    >
      <h4>{{ states.alertSnack.title }}</h4>
      <h5>{{ states.alertSnack.text }}</h5>

      <template v-slot:action="{ }" v-if="states.alertSnack.moreInfo">
        <v-dialog
            v-model="states.alertSnack.viewMoreInfo"
            scrollable
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs2 }">
            <v-btn
                color="white"
                outlined
                v-bind="attrs2"
                @click="states.alertSnack.status = false; states.alertSnack.viewMoreInfo = true"
            >
              Ver Informações
            </v-btn>
          </template>
          <v-card>
            <v-card-title>{{states.alertSnack.moreInfoTitle}}</v-card-title>
            <v-divider v-if="states.alertSnack.moreInfoTitle !== ''"></v-divider>
            <v-card-text style="height: 300px;">
              <v-list-item v-for="(itemList, index) in states.alertSnack.listItens" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ itemList }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="states.alertSnack.viewMoreInfo = false"
              >
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-snackbar>
    <v-form
        ref="form_emissao_nf"
        lazy-validation
    >
      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-expansion-panel
            key="0"
            class="elevation-1"
        >
          <v-expansion-panel-header class="h5">
            Dados Gerais
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container class="px-0 mx-0">
              <v-row>
                <v-col class="col-4 py-1 my-0">
                  <EmpresasSelect
                      ref="empresas"
                      v-model="empresaId"
                      obrigatorio
                      @inputChange="setEmpresaID"
                  />
                </v-col>
                <v-col class="col-4 py-1 my-0">
                  <ProducoesSelect
                      ref="producoes"
                      :empresa="empresaId"
                      v-model="producaoId"
                      obrigatorio
                  />
                </v-col>
                <v-col class="col-4 py-1 px-0 m-0">
                  <Pedidos
                      ref="pedidos"
                      :empresaId="empresaId"
                      :producaoId="producaoId"
                      :pedidoId="pedidoId"
                      :dadosnf="dadosnf"
                      :states="states"
                      :colors="colors"
                      @inputChange="setPedidoID"

                      @setPedidoID="setPedidoID"
                      @setStates="setStates"
                      @setDadosNF="setDadosNF"
                      @showRPA="showRPA"
                      @getTalhoes="getTalhoes"
                  />
                </v-col>
              </v-row>
              <DadosGerais
                  ref="dadospedidos"
                  :empresaId="empresaId"
                  :producaoId="producaoId"
                  :dadosnf="dadosnf"
                  :states="states"

                  @setStates="setStates"
                  @setDadosNF="setDadosNF"
              />
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
            v-if="states.show.produtos"
            key="1"
            class="elevation-1"
        >
          <v-expansion-panel-header class="h5">
            Produtos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="tb_produtos">
              <v-data-table
                  dense
                  :headers="headersProdutos"
                  :items="dadosnf.produtos"
                  :items-per-page="-1"
                  hide-default-footer
                  class="p-0 m-0"
              >
                <template v-slot:[`item.qtde`]="{ item }">
                  <span class="num_margin">{{ item.qtde }}</span>
                </template>
                <template v-slot:[`item.unidade_medida`]="{ item }">
                  {{ item.unidade_medida }}
                </template>
                <template v-slot:[`item.vr_unit`]="{ item }">
                  <span class="num_margin">{{ $stringFormat.formatNumber(item.vr_unit) }}</span>
                </template>
                <template v-slot:[`item.vr_total`]="{ item }">
                  <span class="num_margin">{{ $stringFormat.formatNumber(item.vr_total) }}</span>
                </template>
                <template v-slot:[`item.icms`]="{ item }">
                  <span class="num_margin">{{ $stringFormat.formatNumber(item.icms) }}</span>
                </template>

                <template v-slot:[`item.talhao_id`]="{ item }">
                  <v-autocomplete
                      v-model="item.talhao_id"
                      :items="getTalhaoCultura(dadosnf.talhaoOptions,item.variedade_cultura_id)"
                      item-text="descricao"
                      item-value="id"
                      dense
                      outlined
                      :rules="talhaoRule"
                      class="mt-2"
                      :disabled="states.field_readonly.talhao"
                  />
                </template>

                <template v-slot:[`item.palete`]="{ item }">
                  <v-autocomplete
                      v-model="item.palete_id"
                      :items="dadosnf.paleteOptions"
                      item-text="descricao"
                      item-value="id"
                      dense
                      outlined
                      class="mt-2"
                      :disabled="states.field_readonly.palete || item.paletizado !== 'P'"
                      :rules="[
                        (v) => (v !== 'N' && item.paletizado === 'P') || item.paletizado !== 'P'
                        || 'Selecione o Palete'
                      ]"
                  />
                </template>
              </v-data-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
            v-if="states.show.descontos"
            key="2"
            class="elevation-1"
        >
          <v-expansion-panel-header class="h5">
            Descontos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Descontos
                ref="descontos"
                :empresaId="empresaId"
                :producaoId="producaoId"
                :pedidoId="pedidoId"
                :dadosnf="dadosnf"
                :states="states"
                :maskmoney="maskmoney"
                :colors="colors"

                @setStates="setStates"
                @setDadosNF="setDadosNF"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
            v-if="states.show.transporte"
            key="3"
            class="elevation-1"
        >
          <v-expansion-panel-header class="h5">
            Transporte
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Transporte
                ref="transporte"
                :empresaId="empresaId"
                :producaoId="producaoId"
                :pedidoId="pedidoId"
                :dadosnf="dadosnf"
                :states="states"
                :maskmoney="maskmoney"
                :colors="colors"

                @setStates="setStates"
                @setDadosNF="setDadosNF"
                @operacaoFreteChange="showRPA"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
            v-if="states.show.rpa"
            key="4"
            class="elevation-1"
        >
          <v-expansion-panel-header class="h5">
            RPA
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <RPA
                ref="rpa"
                :empresaId="empresaId"
                :producaoId="producaoId"
                :pedidoId="pedidoId"
                :dadosnf="dadosnf"
                :states="states"
                :maskmoney="maskmoney"
                :colors="colors"

                @setStates="setStates"
                @setDadosNF="setDadosNF"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-container class="px-0 mx-0">
        <v-row class="mt-2 px-0 mx-0">
          <v-col class="col-11" />
          <v-col
              v-if="states.show.btn_emitir_nf == true"
              class="col-1 py-1 my-0 mx-0 px-0"
          >
            <v-btn
                block
                large
                color="success"
                @click="emitirNF"
            >
              Emitir NF
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <div
        v-if="states.loadingNf"
        class="text-center p-4"
    >
      Carregando...<br><br>
      <v-progress-circular
          :size="80"
          :width="2"
          color="primary"
          indeterminate
      />
    </div>
    <v-dialog
        v-model="states.dialogEmissaoNF"
        transition="dialog-bottom-transition"
        max-width="400"
        persistent
    >
      <v-card>
        <v-toolbar
            color="info"
            dark
        >
          <v-spacer />
          <span class="headline">Emitindo Nota Fiscal</span>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <div class="text-center p-5">
            <h4 class="mb-4">
              Aguarde...
            </h4>
            <v-progress-circular
                :size="100"
                :width="2"
                color="primary"
                indeterminate
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.checkbox_transporte .v-label {
  font-size: 1rem
}
.tb_produtos th,
.tb_produtos td,
.tb_produtos .v-select__slot {
  font-size: 14px !important
}
</style>

<script>

import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import Pedidos from './components/PedidosComponent'
import DadosGerais from './components/DadosGeraisComponent'
import Descontos from './components/DescontosComponent'
import Transporte from './components/TransporteComponent'
import RPA from './components/RPAComponent'

export default {
  components: { Pedidos, DadosGerais, Descontos, Transporte, RPA },

  data: () => ({
    panel: [],
    items: 5,

    headersProdutos: [
      { text: 'Nome do Produto', value: 'nome' },
      { text: 'Descrição', value: 'descricao' },
      { text: 'ST', value: 'st' },
      { text: 'Qtde', value: 'qtde', align: 'right' },
      { text: 'Vr. Unit.', value: 'vr_unit', align: 'right' },
      { text: 'Vr. Total', value: 'vr_total', align: 'right' },
      { text: '% ICMS', value: 'icms', align: 'right' },
      { text: 'Talhão', value: 'talhao_id', width: '250' },
      { text: 'Palete', value: 'palete', width: '250' },
    ],
    talhaoRule: [(v) => !!v || 'Selecione o Talhão'],

    maskmoney: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
    },

    empresaId: '',
    producaoId: '',
    pedidoId: '',

    dadosnf: {
      pedido_select_id: '',
      dataAtual: '',
      nf: '',
      serie: '',
      cliente_id: '',
      data_emissao: '',
      data_saida: '',
      transacao_id: '',
      condicao_pagto_id: '',
      forma_pagto_id: '',
      observacao: '',
      comercial_perc: 0,
      comercial_val: 0,
      bonificacao_perc: 0,
      bonificacao_val: 0,
      icms_perc: 0,
      icms_val: 0,
      transportadora_id: '',
      transportadora: '',
      transportadora_select: '',
      pagrpa: '',
      motorista_id: '',
      placa: '',
      placa_uf: '',
      placa_tipo: '',
      placa_reboque: '',
      placa_reboque_uf: '',
      placa_reboque_tipo: '',
      peso_bruto: 0,
      peso_liquido: 0,
      frete_conta: '',
      valor_frete: 0,
      valor_negociado: 0,
      operacao_frete: '',
      produtos: [
        {
          id: '',
          descricao: '',
          st: '',
          qtde: '',
          nidade_medida: '',
          vr_unit: '',
          vr_total: '',
          icms: '',
          talhao_id: '',
          palete_id: '',
        },
      ],

      transporte_checkbox1_valor: '',
      transporte_checkbox1_label: '',
      transporte_checkbox1_status: false,
      transporte_checkbox1_disabled: true,

      transporte_checkbox2_valor: '',
      transporte_checkbox2_label: '',
      transporte_checkbox2_status: false,
      transporte_checkbox2_disabled: true,

      transporte_checkbox3_valor: '',
      transporte_checkbox3_label: '',
      transporte_checkbox3_status: false,
      transporte_checkbox3_disabled: true,

      num_contrato: '',
      vr_adiantamento: '',
      operacao_id: '',
      roteiro_id: '',
      percurso_id: '',
      status_pedido: '',

      clientesOptions: [{ id: '', nome: 'Selecione um Cliente' }],
      transacoesOptions: [{ id: '', descricao: 'Selecione uma Transação' }],
      condicaoPagtoOptions: [{ id: '', descricao: 'Selecione uma Condição de Pagto.' }],
      formaPagtoOptions: [{ id: '', descricao: 'Selecione uma Forma de Pagto.' }],
      motoristaOptions: [],
      UFOptions: ['', 'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      freteContaOptions: [
        { id: 'C', descricao: '0 - Emitente' },
        { id: 'F', descricao: '1 - Destinatário' },
        { id: 'X', descricao: 'X - Sem Frete' },
      ],
      operacaoFreteOptions: [
        { id: '', descricao: 'Selecione a Operação' },
        { id: '0', descricao: '0 - Cliente/Retirada' },
        { id: '1', descricao: '1 - Repom' },
        { id: '3', descricao: '3 - Planilha/Transportadora' },
        { id: '4', descricao: '4 - Sem Frete' },
        { id: '5', descricao: '5 - NDD' },
      ],
      operacaoOptions: [{ id: '', descricao: 'Selecione a Operação' }],
      roteiroOptions: [{ id: '', descricao: 'Selecione o Roteiro' }],
      percursoOptions: [{ id: '', descricao: 'Selecione o Percurso' }],
      talhaoOptions: [{ id: '', descricao: 'Selecione o Talhão' }],
      paleteOptions: [
        { id: 'N', descricao: 'Nenhum' },
        { id: 'D', descricao: 'Descartável' },
        { id: 'F', descricao: 'Fixo' },
        { id: 'R', descricao: 'Retornável ' },
      ],
      listaPedidosOptions: [],
      transportadoraOptions: [],
      veiculoOptions: [],
      veiculoReboqueOptions: [],
    },

    colors: {
      success: 'success',
      alert: 'amber',
      danger: 'red darken-3',
    },

    states: {
      show: {
        dados_gerais: false,
        produtos: false,
        descontos: false,
        transporte: false,
        rpa: false,
        btn_emitir_nf: false,
        num_contrato: false,
        vr_adiantamento: false,
        operacao: false,
        roteiro: false,
        percurso: false,
      },

      field_readonly: {
        nf: false,
        serie: false,
        cliente: false,
        data_emissao: true,
        data_saida: true,
        transacao: false,
        condicao: false,
        forma_pagto: false,
        observacao: false,
        talhao: false,
        palete: false,
        desconto_comercial: false,
        desconto_bonificacao: false,
        desconto_icms: false,
        transportadora: false,
        motorista: false,
        placa: false,
        uf: false,
        placa_reboque: true,
        uf_reboque: true,
        peso_bruto: false,
        peso_liquido: false,
        frete_conta: false,
        valor_frete: false,
        valor_negociado: false,
        operacao_frete: false,
        pagrpa: false,
        num_contrato: false,
        vr_adiantamento: false,
        operacao: false,
        roteiro: false,
        percurso: false,
      },
      loadingNf: false,
      loadingTransportadora: false,
      valid_buscar_nf: '',
      valid_produtos_nf: '',

      dialogListaPedido: false,
      dialogTransportadora: false,
      dialogEmissaoNF: false,

      alertSnack: {
        status: false,
        title: '',
        text: '',
        color: 'success',
        moreInfo: false,
        viewMoreInfo: false,
        moreInfoTitle: '',
        listItens: [],
      },
    },
    searchTransportadora: null,
  }),

  computed: {
  },

  watch: {},

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Vendas', route: 'vendas' },
      { title: 'Faturamento', route: 'faturamento' },
      { title: 'Nota Fiscal' },
    ])

    this.dadosnf.dataAtual = this.$date.getISOToday()
    this.dadosnf.data_emissao = this.$date.getISOToday()
    this.dadosnf.data_saida = this.$date.getISOToday()
    const pedidoId = parseInt(this.$route.query.pedido_id)
    const empresaId = parseInt(this.$route.query.empresa_id)
    const producaoId = parseInt(this.$route.query.producao_id)

    if (empresaId > 0 && producaoId > 0 && pedidoId > 0) {
      this.empresaId = empresaId
      this.producaoId = producaoId
      this.pedidoId = pedidoId
    }

    this.panel = [0, 1, 2, 3, 4]
  },

  created () {
    window.addEventListener('beforeunload', this.closeTab)
  },

  methods: {
    closeTab () {
      localStorage.setItem('visualizando_pedido', 'false')
    },

    setPedidoID (value) {
      this.pedidoId = value
    },

    setStates (value) {
      this.states = value
    },

    setDadosNF (value) {
      this.dadosnf = value
    },

    checkPedido () {
      if (this.pedidoId < 0 || this.pedidoId === '') {
        this.states.show.btn_emitir_nf = false
      }
    },

    emitirNF () {
      if (this.$refs.form_emissao_nf.validate() === true) {
        this.states.dialogEmissaoNF = true
        this.states.show.btn_emitir_nf = false
        this.$refs.pedidos.emitirNF()
      } else {
        this.states.alertSnack.status = true
        this.states.alertSnack.title = 'Erro'
        this.states.alertSnack.text = 'Para emitir a nota fiscal, é necessário verificar os campos em vermelho'
        this.states.alertSnack.color = this.colors.danger
        this.states.show.btn_emitir_nf = true
      }
    },

    validateForm () {
      this.$refs.form_emissao_nf.validate()
    },

    getTalhoes () {
      if (this.empresaId !== '' && this.producaoId !== '' && this.pedidoId !== '') {
        const params = `?empresa_id=${this.empresaId}&producao_id=${this.producaoId}&pedido_id=${this.pedidoId}&data_emissao=${this.dadosnf.data_emissao}`
        const talhoes = []

        ApiService.get('/sapiens/nota-fiscal/talhoes', params).then((res) => {
          for (const talhao of res.data.data) {
            talhoes.push({
              id: talhao.talhao_id,
              descricao: talhao.destal,
              produto_id: talhao.produto_id,
            })
          }
        })

        this.dadosnf.talhaoOptions = talhoes
      }
    },

    getTalhaoCultura (talhoesList, produtoId) {
      const talhaoVariedadeCultura = talhoesList.filter((e) => e.produto_id === produtoId)
      const talhoes = []
      talhoes.push({ id: '', descricao: 'Selecione o Talhão' })

      for (const talhao of talhaoVariedadeCultura) {
        talhoes.push({
          id: talhao.id,
          descricao: talhao.descricao,
          produto_id: talhao.produto_id,
        })
      }

      return talhoes
    },

    showRPA () {
      if (this.dadosnf.operacao_frete === '1' || this.dadosnf.operacao_frete === '5') {
        this.states.show.rpa = true
        this.states.show.num_contrato = true
        this.states.show.vr_adiantamento = true
        this.states.show.operacao = true
        this.states.show.roteiro = true
        this.states.show.percurso = true

        this.$refs.rpa.getOperacao()
        this.$refs.rpa.getRoteiro()
      } else if (this.dadosnf.operacao_frete === '3') {
        this.states.show.rpa = true
        this.states.show.num_contrato = false
        this.states.show.vr_adiantamento = false
        this.states.show.operacao = false
        this.states.show.roteiro = false
        this.states.show.percurso = false
      } else {
        this.states.show.rpa = false
        this.dadosnf.pagrpa = ''
        this.dadosnf.num_contrato = ''
        this.dadosnf.roteiro_id = ''
        this.dadosnf.percurso_id = ''
        this.dadosnf.operacao_id = ''
      }
    },
  },
}
</script>
